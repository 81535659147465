import citizenshipOptionsTxt from '../assets/citizenshipOptions.txt';
import type { ProFormInstance } from '@ant-design/pro-components';
import {
    ProForm,
    ProFormDatePicker,
    ProFormSelect,
    ProFormText,
    ProFormTextArea,
} from '@ant-design/pro-components';
import { Button, message } from 'antd';
import { useRef, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { HomeOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import {
    useGetFormMutation,
    useSaveFormMutation,
} from "../store/formApiSlice";
import { ResolveTokensData } from './Callback';
import axios from 'axios';
import { RootState } from '../store/rootReducer';
import { setPersonalData } from '../store/retrieveMyInfoSlice';

interface PersonalInfoFormProps {
    personalData?: ResolveTokensData | null; // Allow null
}

function PersonalInfoForm({ }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // ------------ form ref  ----------------//
    const formRef = useRef<
        ProFormInstance<{
            id: string;
            name: string;
            gender: string;
            race: string;
            birthday: string;
            citizenship: string;
            phone: string;
            email: string;
            address: string;
        }> | null
    >(null);

    // ------------- Define the options ----------- //
    const sexOptions = [
        { label: 'Male', value: 'M' },
        { label: 'Female', value: 'F' }
    ];

    const raceOptions = [
        { label: 'Chinese', value: 'CHINESE' },
        { label: 'Malay', value: 'MALAY' },
        { label: 'Indian', value: 'INDIAN' },
        { label: 'Others', value: 'OTHERS' },
    ];

    type CitizenshipOption = { label: string; value: string };
    const [citizenshipOptions, setCitizenshipOptions] = useState<CitizenshipOption[]>([]);

    async function fetchCitizenshipOptions() {
        try {
            const response = await fetch(citizenshipOptionsTxt); // Adjust the path as needed
            const optionsText = await response.text();
            const options = optionsText.split('\n').map((line) => ({
                label: line,
                value: line.toLowerCase(),
            }));
            setCitizenshipOptions(options);
        } catch (error) {
            console.error('Failed to fetch citizenship options:', error);
        }
    }

    useEffect(() => {
        fetchCitizenshipOptions();
    }, []);

    // ------------ get personalData ----------------//

    const personalData = useSelector((state: RootState) => state.retrieveMyInfo.personalData) as unknown as ResolveTokensData;
    console.log("personalData", personalData);

    useEffect(() => {
        // Pre-fill form fields with resolveTokensData when available
        if (personalData) {
            const mappedGender = mapGenderValue(personalData.gender);

            formRef.current?.setFieldsValue({
                id: personalData.uinfin,
                name: personalData.name,
                gender: mappedGender,
                race: personalData.race,
                birthday: personalData.birthday,
                citizenship: personalData.citizenship,
                phone: personalData.phone,
                email: personalData.email,
                address: personalData.address,
            });
        }
    }, [personalData]);

    // ------------ call API ----------------//
    // const [read, { isLoading: isReadLoading }] = useGetFormMutation();
    // const [save, { isLoading: isSaveLoading }] =
    //     useSaveFormMutation();

    const handleReturnButtonClick = () => {
        dispatch(setPersonalData(null));
        if (formRef.current) {
            formRef.current.resetFields();
        }
        navigate("/");
    };

    const handleSuccess = () => {
        dispatch(setPersonalData(null));
        if (formRef.current) {
            formRef.current.resetFields();
        }
        navigate("/success");
    };

    const handleFormSubmit = async (values: any) => {
        try {
            console.log(values);
            const apiUrl = import.meta.env.VITE_API_BASE_URL;
            const res = await axios.post(
                `${apiUrl}/form/create`,
                values
            );
            console.log(res);
            message.success('Submission successful');
            handleSuccess();
        } catch (err: any) {
            console.log(err);
            message.error('Error. Please contact counter for assistance.');
        }
    };

    const mapGenderValue = (originalValue: string) => {
        switch (originalValue.toUpperCase()) {
            case 'MALE':
                return 'M';
            case 'FEMALE':
                return 'F';
            case 'OTHERS':
                return 'O';
            default:
                return originalValue;
        }
    };

    return (
        <>

            <div className="singpass-form bg-white rounded-lg w-10/12 h-10/12 justify-center items-center px-8 py-5">
                <Button className="home-button" type="primary" shape="circle" icon={<HomeOutlined />} size="large" onClick={handleReturnButtonClick} />
                <strong style={{ fontSize: "25px", marginBottom: "30px" }}>Please fill in your Personal Info</strong>
                <ProForm<{
                    id: string;
                    name: string;
                    gender: string;
                    race: string;
                    birthday: string;
                    citizenship: string;
                    phone: string;
                    email: string;
                    address: string;
                }>
                    formRef={formRef}
                    layout='horizontal'
                    labelCol={{ span: 7 }}
                    submitter={{
                        searchConfig: {
                            submitText: 'Submit',
                        },
                        render: (props, doms) => {
                            return (
                                <div>
                                    {doms}
                                </div>
                            );
                        },
                        submitButtonProps: {
                            className: 'form-submit-button',
                        },
                        resetButtonProps: {
                            disabled: true,
                            hidden: true
                        },
                    }}
                    onFinish={async (values) => {
                        handleFormSubmit(values);
                    }}
                    // formKey="base-form-use-demo"
                    // request={async () => {
                    //     let res = await readInfo(infoId).unwrap();
                    //     return {
                    //       ...res
                    //     };
                    //   }}
                    autoFocusFirstInput
                >
                    <ProFormText
                        disabled={personalData != null}
                        width="md"
                        name="id"
                        label="NRIC/FIN"
                        placeholder=""
                        rules={[
                            { required: true, message: 'Please enter your NRIC/FIN.' },
                        ]}
                    />
                    <ProFormText
                        disabled={personalData != null}
                        width="md"
                        name="name"
                        label="Full Name"
                        placeholder=""
                        rules={[
                            { required: true, message: 'Please enter your name.' },
                        ]}
                    />
                    <ProFormSelect
                        disabled={personalData != null}
                        style={{ height: "25px" }}
                        width="md"
                        name="gender"
                        label="Gender"
                        placeholder=""
                        options={sexOptions}
                        rules={[
                            { required: true, message: 'Please select your sex.' },
                        ]}
                    />
                    <ProFormSelect
                        disabled={personalData != null}
                        style={{ height: "25px" }}
                        width="md"
                        name="race"
                        label="Race"
                        placeholder=""
                        options={raceOptions}
                        rules={[
                            { required: true, message: 'Please select your race.' },
                        ]}
                    />
                    <ProFormDatePicker
                        disabled={personalData != null}
                        width="md"
                        name="birthday"
                        label="Birthday"
                        placeholder=""
                        rules={[
                            { required: true, message: 'Please select your birthday.' },
                        ]}
                        dataFormat='YYYY-MM-DD'
                    />
                    <ProFormSelect
                        disabled={personalData != null}
                        style={{ height: "25px" }}
                        width="md"
                        name="citizenship"
                        label="Citizenship"
                        placeholder=""
                        options={citizenshipOptions}
                        rules={[
                            { required: true, message: 'Please select your citizenship.' },
                        ]}
                        fieldProps={{
                            showSearch: true,
                        }}
                    />
                    <ProFormText
                        width="md"
                        name="phone"
                        label="Mobile Phone No"
                        placeholder=""
                        rules={[
                            { required: true, message: 'Please enter your phone.' },
                            {
                                pattern: /^\+?-?\d{6,13}$/,
                                message: 'Please enter a valid phone number',
                            },
                        ]}
                    />
                    <ProFormText
                        width="md"
                        name="email"
                        label="Email"
                        placeholder=""
                        rules={[
                            { type: "email", message: "Please enter a valid email" },
                        ]}
                    />
                    <ProFormTextArea
                        disabled={personalData != null}
                        width="md"
                        name="address"
                        label="Home Address"
                        placeholder=""
                        rules={[
                            { required: true, message: 'Please enter your home address.' },
                        ]}
                    />
                </ProForm>
            </div>
        </>
    );

}

export default PersonalInfoForm;