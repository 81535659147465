import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { setMyInfoCode, setPersonalData } from '../store/retrieveMyInfoSlice';
import { Spin } from 'antd';
import axios from '../utility/axiosInstance';
import PersonalInfoForm from './PersonalInfoForm';

export interface ResolveTokensData {
    id: string;
    uinfin: string;
    name: string;
    gender: string;
    race: string;
    birthday: string;
    citizenship: string;
    phone: string;
    email: string;
    address: string;
}

// const testObject = `{
//     "id": "2",
//     "uinfin": "S0290695C",
//     "name": "BERNARD LI GUO HAO",
//     "gender": "FEMALE",
//     "race": "CHINESE",
//     "birthday": "1991-12-04T00:00:00.000+00:00",
//     "citizenship": "MALAYSIAN",
//     "phone": "87163551",
//     "email": "dbstesting03@gmail.com",
//     "address": "#9-128, 102 PEARL GARDEN, BEDOK NORTH AVENUE 4, 460102, SINGAPORE"
// }`;
// const parsedTestObject = JSON.parse(testObject);
// console.log("parsedTestObject", parsedTestObject);

const Callback = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const sessionId = sessionStorage.getItem('singpassSessionId');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log('MyInfoCode:', code);
        // dispatch(setMyInfoCode(code));    //redux store
        sessionStorage.setItem('myinfoCode', code ?? '');
        if (code != '' && sessionId != '') {
            resolveTokenApi();
        }
        // setResolveTokensData(parsedTestObject);
    }, [code]);
    
    const resolveTokenApi = async () => {
        // Step 4: Resolve tokens using the callback response
        try {
            const apiUrl = import.meta.env.VITE_API_BASE_URL;
            const resolveTokensResponse = await axios.post(
                `${apiUrl}/singpass/resolveTokens`,
                {
                    sessionId: sessionId,
                    code: code,
                    grantType: null,
                    clientId: null,
                    redirectUrl: null
                }
            );
            dispatch(setPersonalData(resolveTokensResponse.data));
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
            navigate("/form");
        }
    };

    return (
        <Spin spinning={loading} fullscreen size='large' tip="Loading... Please wait for a while."/>
    );
};

export default Callback;
