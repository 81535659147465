import { Outlet } from "react-router-dom";
import './App.css';
import { ConfigProvider } from "antd";
import enGB from "antd/locale/en_GB";


const App: React.FC = () => {
  return (
    <div className="singpass-form-div singpass-form bg-white border border-gray-200 rounded-lg shadow w-10/12 justify-center items-center">
      <ConfigProvider
        locale={enGB}
        theme={{
          token: {
            colorPrimary: "rgb(177, 160, 134)",
            fontFamily: "var(--main-font-family)",
            colorPrimaryHover: "#3753C6",
            fontSize: 16
          },
          components: {
            Button:{
              fontSize: 20,
            },
            Spin:{
              colorPrimary: "rgb(177, 160, 134)",
            }
          },
        }}
      >
        <Outlet />
      </ConfigProvider>
    </div>
  );
};

export default App;
