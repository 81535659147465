import hslogo from '../assets/hslogo.png';
import RedirectButton from './RedirectButton';
import axios from '../utility/axiosInstance';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setPersonalData } from '../store/retrieveMyInfoSlice';

import singpass_retrieve from '../assets/singpass_retrieve.png';
import { Spin } from 'antd';

function Home() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPersonalData(null));
    }, []);
    
    const [loading, setLoading] = useState(false);

    const apiUrl = import.meta.env.VITE_API_BASE_URL;

    const callSingpassApi = async () => {
        setLoading(true);

        try {
            // Step 1: Get session_id and code_challenge
            const generateCodeResponse = await axios.get(
                `${apiUrl}/singpass/generateCodeChallenge`
            );
            const { session_id, code_challenge } = generateCodeResponse.data;

            // Store the session_id in the browser (use sessionStorage, localStorage, or cookies)
            sessionStorage.setItem('singpassSessionId', session_id);

            // Step 2: Call the Singpass API with code_challenge
            const code = code_challenge;
            try {
                const authorizeUrl = await axios.get(
                    `${apiUrl}/singpass/authorize/${code}`
                );
                window.location.href = authorizeUrl.data;
                setLoading(false);
            } catch (error) {
                console.error('Error processing Singpass Authorize response:', error);
            }

            // Step 3: Listen to /callback and pass the json data to callbackCode
            // Step 4: Resolve tokens using the callback response

        } catch (error) {
            console.error('Error:', error);
        }
    };


    return (
        <div className="h-5/6 flex flex-col justify-center items-center">
            <img src={hslogo} className="mx-auto"></img>
            <div className='flex'>&nbsp;</div>
            <div className='home-title text-4xl font-bold hengseng-gray-500'>Register Member</div>
            <div className='flex'>&nbsp;</div>
            <div className='flex'>&nbsp;</div>
            <div className='flex'>&nbsp;</div>
            <span className="text-2xl mb-8">
                <img src={singpass_retrieve} alt="Retrieve MyInfo" className="singpass-image hover:opacity-80 cursor-pointer" onClick={() => callSingpassApi()}
                    style={{ width: '35vw' }}
                />
            </span>
            {/* <RedirectButton label='Fill in Manually' to='form' update='1' size='lg' /> */}
            <Spin spinning={loading} fullscreen size='large' tip="Loading... Redirect to Singpass Page..." />
        </div>
    );

}

export default Home;