// counterSlice.js
import {createSlice} from '@reduxjs/toolkit';

export const retrieveMyInfoSlice = createSlice({
  name: 'myinfo',
  initialState: {
    myinfoCode: "",
    personalData:""
  },
  reducers: {
    setMyInfoCode: (state, action) => {
      state.myinfoCode = action.payload;
    },
    setPersonalData: (state, action) => {
      state.personalData = action.payload;
    }
  }
});

export const {
  setMyInfoCode,
  setPersonalData
} = retrieveMyInfoSlice.actions;

export default retrieveMyInfoSlice.reducer;
