import {configureStore} from '@reduxjs/toolkit';
import retrieveMyInfoSlice from './retrieveMyInfoSlice';

const store = configureStore({
    reducer: {
        retrieveMyInfo: retrieveMyInfoSlice
    },
});

// Define your RootState type based on the store's state
export type RootState = ReturnType<typeof store.getState>;

// Define AppDispatch type based on the store's dispatch
export type AppDispatch = typeof store.dispatch;

export default store;