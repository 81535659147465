import React from "react";
import {
  BrowserRouter as Router, Route, Routes
} from "react-router-dom";
import ReactDOM from 'react-dom/client';
import Home from './components/Home.tsx';
import MyInfo from './components/MyInfo.tsx';
// import RetrieveMyInfo from './components/RetrieveMyInfo.tsx';
import PersonalInfoForm from './components/PersonalInfoForm.tsx';
import App from './App.tsx';
import './index.css';
import { Provider } from 'react-redux';
import store from './store/store.ts';
import Callback from "./components/Callback.tsx";
import SuccessPage from "./components/SuccessPage.tsx";

const AppRoutes: React.FC = () => (
  <Routes>
    <Route path="/" element={<App />}>
      <Route index element={<Home />} />
      <Route path="/myinfo" element={<MyInfo />} />
      {/* <Route path="/retrieve" element={<RetrieveMyInfo />} /> */}
      <Route path="/form" element={<PersonalInfoForm />} />
      <Route path="/callback" element={<Callback />} />
      <Route path="/success" element={<SuccessPage />} />
    </Route>
  </Routes>
);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <Router basename="/">
      <AppRoutes />
    </Router>
  </Provider>
);